const meta = {
  __alias: 'Gryphon.core.utils.LocationProxy'
};
class LocationProxy {
  get pathname() {
    return location.pathname;
  }
  set pathname(pathname) {
    location.pathname = pathname;
  }
  get hash() {
    return location.hash;
  }
  set hash(hash) {
    location.hash = hash;
  }
  get origin() {
    return location.origin;
  }
  set origin(origin) {
    location.origin = origin;
  }
  replace() {
    location.replace(...arguments);
  }
  reload() {
    location.reload();
  }
}
module.exports = new LocationProxy();