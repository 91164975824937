const locationProxy = require('Gryphon.core.utils.LocationProxy');

const meta = {
    __alias: 'Gryphon.organization.user.account.ownaccount.login.Redirector'
};

class Redirector {

    constructor($organizationInputArg, redirectRulesArg) {
        this._$organizationInput = $organizationInputArg;

        // organizationAlias: "Integrity1"
        // organizationID: 1001
        // url: "http://localhost:8080"
        this._redirectRules = redirectRulesArg;
    }

    initRedirectionGuard() {
        if (this._redirectRules.length) {
            this._redirectIfNeeded();
            this._$organizationInput.bind('blur change paste', () => this._redirectIfNeeded());
        }
    }

    restoreAfterRedirect() {
        this._restoreOrgName();
        Redirector.clearHash();
    }

    _getRedirectURL(orgName) {
        let redirectURL = '';

        this._redirectRules.find(({ url, organizationAlias: name, organizationID: id }) => {
            if (url && url !== locationProxy.origin && [name.toLowerCase(), '' + id].includes(orgName.toLowerCase())) {
                let hash = `orgName=${orgName}`;

                if (locationProxy.hash) {
                    hash += `;hash=${Redirector._getTargetHash()}`;
                }
                redirectURL = `${url}${locationProxy.pathname}#${hash}`;

                return true;
            }
        });

        return redirectURL;
    }

    _redirectIfNeeded() {
        this._redirectOrganization(this._getRedirectURL(this._$organizationInput.val()));
    }

    _redirectOrganization(url) {
        if (url && !this._isRedirected) {
            this._isRedirected = true;
            locationProxy.replace(url);
        }
    }

    _restoreOrgName() {
        const orgName = Redirector._getOrgNameFromHash();
        if (orgName) this._$organizationInput.val(orgName);
    }

    static clearHash() {
        locationProxy.hash = Redirector._getTargetHash();
    }

    static _getTargetHash() {
        // #orgName=asd;hash=asdsd/asddas
        // #orgName=asd
        // #asdsd/asddas
        // ''
        let hash = locationProxy.hash.replace('#', '');

        if (Redirector._getOrgNameFromHash()) {
            if (hash.includes(';hash=')) {
                hash = hash.replace(/.*hash=/, '');
            } else {
                hash = '';
            }
        }

        return hash;
    }

    static _getOrgNameFromHash() {
        return locationProxy.hash.includes('#orgName=') ?
            locationProxy.hash.replace(/^#orgName=/, '').replace(/;hash.*/, '') :
            '';
    }
}

module.exports = Redirector;
