const meta = {
  __alias: 'Gryphon.organization.user.account.ownaccount.login.CapsLock'
};
module.exports = function () {
  var _capsLock;
  $(document).on('keypress', _runHandleKeyPress);
  $(document).on('keyup', _runHandleCapsKey);
  function _runHandleKeyPress(e) {
    var keyCode;
    e = e || window.event;
    keyCode = e.keyCode ? e.keyCode : e.which;
    _capsLock = !!(keyCode >= 65 && keyCode <= 90 && !e.shiftKey || keyCode >= 97 && keyCode <= 122 && e.shiftKey);
    _changeCapsLock();
  }
  function _runHandleCapsKey(e) {
    var keyCode;
    e = e || window.event;
    keyCode = e.keyCode ? e.keyCode : e.which;
    if (keyCode === 20 && _capsLock !== undefined) {
      _capsLock = !_capsLock;
      _changeCapsLock();
    }
  }
  function _changeCapsLock() {
    document.getElementById('capsLockAlert').style.display = _capsLock ? 'block' : 'none';
  }
};