const watchCapsLock = require('Gryphon.organization.user.account.ownaccount.login.CapsLock');
const Redirector = require('Gryphon.organization.user.account.ownaccount.login.Redirector');

const meta = {
    __alias: 'Gryphon.organization.user.account.ownaccount.login.Login'
};

const isBrowserSupported = () => {
    const userAgent = navigator.userAgent.toLocaleLowerCase();
    const userAgentBrands = (navigator.userAgentData && navigator.userAgentData.brands) || [];
    const isEdge = /edg/.test(userAgent)
        || userAgentBrands.some(brand => /edg/.test(brand.brand.toLocaleLowerCase()));

    return (/chrome/.test(userAgent) || /safari/.test(userAgent)) && !(isEdge || /opr/.test(userAgent));
};

window.onload = function() {
    if (!isBrowserSupported()) {
        document.querySelector('#login-form').style.display = 'none';
        document.querySelector('#upgradeBrowser').style.display = 'block';
        document.querySelector('.backLoginLink').style.display = 'none';

        return;
    }

    if (window.warning || window.error) {
        /*
            POTENTIAL VULNERABILITY FOR INSECURE VARIABLE RENDERING (TYPE 3) 
            ANALYSED RISK: safe - our data
            DATA TYPE: our data
        */
        $('#message').flashMessage({
            warning: window.warning ? window.warning : '',
            error: window.error ? window.error : ''
        });
    } else if (sessionStorage.getItem('loggedOutMessage')) {
        const loggedOutMessage = JSON.parse(sessionStorage.getItem('loggedOutMessage'));
        // If they were logged out in the past 30 seconds, show the logout message
        if (Date.now() - loggedOutMessage.dateTime < 30*1000) {
            const options = {};

            options[loggedOutMessage.type] = loggedOutMessage.message;
            /*
                POTENTIAL VULNERABILITY FOR INSECURE VARIABLE RENDERING (TYPE 3) 
                ANALYSED RISK: safe - our data
                DATA TYPE: our data
            */
            $('#message').flashMessage(options);
            sessionStorage.removeItem('loggedOutMessage');
        }
    }

    watchCapsLock();
    const redirector = new Redirector($('#organization'), window.redirectRules);
    redirector.initRedirectionGuard();
    redirector.restoreAfterRedirect();

    $('#login-form').validate({
        focusInvalid: false,
        highlight: function(element, errorClass) {
            $(element).addClass(errorClass);
            $(element.form).find('label[for=' + element.id + ']').addClass('labelError');
        },
        unhighlight: function(element, errorClass) {
            $(element).removeClass(errorClass);
            $(element.form).find('label[for=' + element.id + ']').removeClass('labelError');
        },
        errorPlacement: function() {
            return true;
        },
        submitHandler: function(form) {
            if ($('#login-form').valid()) {
                $('#send').attr('disabled', 'disabled');
                form.submit()
            }
        },
        rules: {
            organization: {
                required: true
            },
            username: {
                required: true
            },
            password: {
                required: true
            }
        }
    });
};

function backToLogin (){
    $('#login-form').show();
    $('#upgradeBrowser').hide();
    $('#organization').focus();
}
